import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme();

export default function SignInSide() {
  const getUrl = (subdomain) => `https://${subdomain}.${window.location.host}`;

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(lukas-blazek-mcSDtbWXUZU-unsplash.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Velg admin-panel
            </Typography>
            <Box component="div" sx={{ mt: 1 }}>
              <Button
                type="link"
                href={getUrl("toppbonus")}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, pt: 2, pb: 2 }}
              >
                ToppBonus
              </Button>

              <Button
                type="link"
                href={getUrl("menyvelgeren")}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, pt: 2, pb: 2 }}
              >
                Menyvelgeren
              </Button>

              <Button
                type="link"
                href={getUrl("monitoring")}
                fullWidth
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
              >
                Monitoring
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
